import rangeSlider from 'range-slider-input';

export default class FormRange {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.id = this.root.getAttribute('id');
        this.slider = this.root.querySelector('[data-form-range-slider]');
        this.minInput = this.root.querySelector('[data-form-range-min-input]');
        this.maxInput = this.root.querySelector('[data-form-range-max-input]');

        this.sliderInstance = null;

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));

        return true;
    }

    init() {
        this.setRangeSlider();
    }

    setRangeSlider() {
        if (!this.slider) return;

        this.sliderInstance = rangeSlider(this.slider, {
            value: [this.settings.init_min, this.settings.init_max],
            min: this.settings.min,
            max: this.settings.max,
            step: 1,
            onInput: (value, userInteraction) => {
                const min = value[0];
                const max = value[1];

                this.minInput.value = min;
                this.maxInput.value = max;
            },
        });
    }

    disable() {
        this.root.classList.add('-disabled');
        this.sliderInstance.disabled(true);
        this.sliderInstance.value([]);
        this.minInput.value = null;
        this.maxInput.value = null;
    }

    enable() {
        this.root.classList.remove('-disabled');
        this.sliderInstance.disabled(false);
        this.sliderInstance.value([
            this.settings.init_min,
            this.settings.init_max,
        ]);
    }
}
